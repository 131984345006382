




























import { Component, Vue, Ref } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import TableBase from '@/components/atoms/TableBase.vue'
import ModalDefaultExitCondition from '@/components/organisms/ModalDefaultExitCondition.vue'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    TableBase,
    ModalDefaultExitCondition,
  },
})
export default class DefaultExitCondition extends Vue {
  private breadcrumbs = [
    { text: '設定', href: '/teacher/setting/top' },
    { text: 'デフォルト終了条件', active: true },
  ]

  private tableBasePerPage = null

  private tableBaseChallengeItems = []

  private tableBaseHomeworkItems = []

  private tableBaseFields = [
    { key: 'subject', label: '教科' },
    { key: 'item', label: '制限項目' },
    { key: 'limit', label: '制限しきい値' },
    { key: 'action', label: '' },
  ]

  private tableBaseButtons = ['action']

  @Ref()
  modalCondition!: ModalDefaultExitCondition

  private showDefaultExitCondition(number: number, index: number): void {
    const item: any = number == 1 ? this.tableBaseChallengeItems[index] : this.tableBaseHomeworkItems[index]
    this.modalCondition.setDefaultExitCondition(item.id, item.subject, item.itemId, item.threshold)
    this.modalCondition.show()
  }

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private mounted() {
    this.loadDatas()
  }

  public async loadDatas() {
    await Vue.prototype.$http.httpWithToken.get(`/branch_end_conditions?branchId=${this.branchId}`).then((res: any) => {
      const categories = res.data.reduce(
        (accumulator: any, category: { branchEndConditions: any; classCategoryName: any }) => {
          accumulator[category.classCategoryName] = category.branchEndConditions.map(
            (endCondition: {
              id: number
              name: string
              subjectName: string
              threshold: string
              restrictionName: string
              restrictionId: number
              restrictionUnit: string
            }) => {
              return {
                id: endCondition.id,
                subject: endCondition.subjectName,
                item: endCondition.restrictionName,
                limit: endCondition.threshold + endCondition.restrictionUnit,
                action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
                itemId: endCondition.restrictionId,
                threshold: endCondition.threshold,
              }
            }
          )
          return accumulator
        },
        {}
      )
      this.tableBaseChallengeItems = categories['演習']
      this.tableBaseHomeworkItems = categories['宿題']
    })
  }
}
